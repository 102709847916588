@import 'styles/utils';

$width: 100vw;
$full-width: 100vw;
$width: 42rem;
$angle: -1.3deg;
$abs-angle: max($angle, $angle * -1);
$tan-alpha: tan($abs-angle);
$skew-padding: calc($width * $tan-alpha / 2);
$clip-padding: calc($full-width * $tan-alpha / 2);

.image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.imageDiagonal {
  height: calc(100% + calc(($clip-padding * 2) - ($clip-padding - $skew-padding)) * 2);
  margin: calc(-1 * (($clip-padding * 2) - ($clip-padding - $skew-padding))) 0;
}

.textWrapper {
  height: fit-content;
}

.boxWrapper {
  padding: 50px 0;

  &.noImagePaddingBottomOnMobile {
    padding: 50px 0 0 0;
  }
}

.imageWrapper.imageWrapper.imageWrapper {
  @include breakpoint('md') {
    width: calc(50vw);
    position: absolute;
    left: calc(min(50vw, 705px));
    /* padding-right: 0px; */
    /* padding-left: 0px; */
    padding: 0;
    height: 100%;
    top: 0;
  }
}
.hideImageOnMobile.hideImageOnMobile {
  display: none;

  @include breakpoint('md') {
    display: block;
  }
}
.title.title {
  margin-bottom: 15px;
  line-height: 32px;
  @include breakpoint('md') {
    line-height: 56px;
    margin-bottom: 20px;
  }
  @include breakpoint('xl') {
    @include font-size(48);
  }
}

.subtitle.subtitle {
  line-height: 30px;
  @include breakpoint('md') {
    line-height: 32px;
  }
}

.markdown {
  margin-top: 15px;
  & p {
    line-height: 24px;
  }
  @include breakpoint('md') {
    margin-top: 20px;
  }
}

.button.button {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 30px;
  @include breakpoint('sm') {
    margin: 30px 0;
  }
  @include breakpoint('md') {
    width: auto;
    margin: 20px 0 0 0;
  }
}
