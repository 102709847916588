@import 'styles/utils';

.button.button {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 30px;
  @include breakpoint('sm') {
    margin: 30px 0;
  }
  @include breakpoint('md') {
    width: auto;
    margin: 20px 0 0 0;
  }
}
